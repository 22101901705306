<template>
  <header class="relative h-20">
    <nav ref="navbar" class="mb-50 navbar fixed z-40 h-auto w-full">
      <div class="flex flex-wrap items-center justify-between px-3 py-4 xl:px-10">
        <NuxtLink to="/" class="flex items-center">
          <ImageStrapiImage
            v-if="data?.homeButton?.image"
            :data="data.homeButton.image.data"
            :lazy="false"
            class="m-3 w-20 md:w-24" />
        </NuxtLink>
        <div class="flex items-center gap-x-4 md:gap-x-6 xl:order-2 xl:max-xl:gap-x-2">
          <NuxtLink
            v-show="showCtaButton"
            aria-label="Buche deinen Termin"
            :to="bookingLink"
            class="inline-flex items-center gap-x-2 rounded-md p-2 text-sm font-medium text-gray-900 transition sm:invisible hover:bg-brick-50 focus:outline-none focus:ring-2 focus:ring-brick-300 sm:hidden sm:hover:bg-transparent sm:hover:underline md:p-0">
            <CalendarIcon class="text-xl" />
          </NuxtLink>
          <NuxtLink
            to="/abo?mtm_campaign=header"
            class="invisible hidden text-sm font-medium text-gray-900 hover:underline md:visible md:flex">
            Mitgliedschaft
          </NuxtLink>
          <SharedButton v-show="showCtaButton" :data="ctaButton" class="hidden text-gray-900 sm:flex" />
          <button
            :ref="
              (el) => {
                collapse.toggleEl.value = el;
              }
            "
            type="button"
            class="ml-1 inline-flex items-center rounded-md p-2 text-sm text-gray-900 hover:bg-brick-50 focus:outline-none focus:ring-2 focus:ring-brick-300 xl:hidden"
            aria-controls="mobile-submenu"
            aria-expanded="false">
            <span class="sr-only">Menü öffnen</span>
            <MenuIcon class="text-2xl" />
          </button>
        </div>
        <div
          id="mobile-submenu"
          :ref="
            (el) => {
              collapse.targetEl.value = el;
            }
          "
          class="relative hidden w-full items-center justify-between xl:static xl:order-1 xl:flex xl:w-auto">
          <ul
            ref="mobileNavbar"
            class="mt-4 flex flex-col rounded-xl bg-white text-lg font-medium text-gray-900 shadow-md md:space-x-4 xl:mt-0 xl:flex-row xl:space-x-8 xl:rounded-none xl:bg-transparent xl:text-base xl:shadow-none">
            <li v-for="item in data?.centerNavigation?.navigationItems" :key="item.label">
              <template v-if="item.dropdownItems.length === 0">
                <NuxtLink
                  :to="item.href"
                  :target="isOutgoing(item.href) ? '_blank' : '_self'"
                  class="xl:hover:text-primary-900 z-10 block rounded-xl py-3 pl-3 pr-4 transition hover:bg-gray-50 xl:border-0 xl:p-0 xl:hover:bg-transparent"
                  @click.native="closeDropdown(item.label)"
                  >{{ item.label }}
                </NuxtLink>
              </template>
              <template v-else>
                <button
                  :ref="
                    (el) => {
                      addDropdown('trigger', item.label, el);
                    }
                  "
                  class="xl:hover:text-primary-900 flex w-full items-center justify-between rounded-xl border-gray-100 py-3 pl-3 pr-4 font-medium hover:bg-brick-100 xl:w-auto xl:border-0 xl:p-0 xl:hover:bg-transparent">
                  {{ item.label }}
                  <SvgoChevronRight class="ml-1 rotate-90 xl:text-sm" />
                </button>
                <div
                  :ref="
                    (el) => {
                      addDropdown('menu', item.label, el);
                    }
                  "
                  class="z-10 hidden w-full rounded-xl bg-white shadow-md xl:w-auto xl:grid-cols-3">
                  <div
                    class="max-h-[calc(100vh-8rem)] overflow-y-scroll rounded-xl border-gray-100 bg-white text-gray-900 xl:col-span-2 xl:max-h-[calc(100vh-4rem)] xl:border xl:p-2">
                    <ul>
                      <li v-for="subItem in item.dropdownItems" :key="subItem.label">
                        <NuxtLink
                          :to="subItem.href"
                          :target="isOutgoing(subItem.href) ? '_blank' : '_self'"
                          class="flex flex-col rounded-xl p-3 hover:bg-yellow-300"
                          @click.native="closeDropdown(item.label)">
                          <span class="text-base">{{ subItem.label }}</span>
                          <span class="text-sm font-normal text-gray-800">{{ subItem.description }}</span>
                        </NuxtLink>
                      </li>
                      <li class="block pb-4 pt-8 text-center text-xs font-normal text-gray-300 md:hidden">
                        &copy; haelsi
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </li>
            <li class="md:invisible md:hidden">
              <NuxtLink
                to="/abo?mtm_campaign=header"
                class="z-10 block rounded-xl py-3 pl-3 pr-4 hover:bg-gray-50 xl:border-0 xl:p-0 xl:hover:bg-transparent xl:hover:text-gray-800"
                @click.native="closeDropdown()">
                Mitgliedschaft
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { onClickOutside, useWindowScroll } from "@vueuse/core";
import CalendarIcon from "assets/svg/icons/calendar.svg";
import MenuIcon from "assets/svg/icons/menu.svg";
import { Collapse, Dropdown } from "flowbite";
import { type ComponentPublicInstance, computed, onMounted, type Ref, ref, watch } from "vue";
import type { Navigation } from "~/types/strapi/components/global/interfaces/Navigation";

type DropdownItem = {
  [_key in "trigger" | "menu"]: HTMLElement;
} & {
  dropdown: Dropdown;
};
// Object.keys always returns a string. For simplicity, we set the key to a string here
type Dropdowns = {
  [key: string]: DropdownItem;
};

const props = defineProps<{
  data?: Navigation;
}>();

const collapse: {
  instance: null | Collapse;
  targetEl: Ref<null | Element | ComponentPublicInstance>;
  toggleEl: Ref<null | Element | ComponentPublicInstance>;
} = {
  instance: null,
  targetEl: ref(null),
  toggleEl: ref(null),
};

onClickOutside(collapse.targetEl, closeCollapse);

const dropdowns: Dropdowns = {};

const navbar = ref<HTMLElement | null>(null);
const mobileNavbar = ref<HTMLElement | null>(null);
const navBarIsTransparent = ref(true);

watch(navBarIsTransparent, (newState) => {
  if (newState === false) {
    navbar.value?.classList.add("translucent");
    mobileNavbar.value?.classList.remove("bg-white", "shadow-md");
  } else {
    navbar.value?.classList.remove("translucent");
    mobileNavbar.value?.classList.add("bg-white", "shadow-md");
  }
});

const route = useRoute();
const bookingLink = computed(() => {
  return "/fachrichtungen";
});

const ctaButton = computed(() => {
  return {
    ...props?.data?.ctaButton,
    href: bookingLink.value,
    theme: "primary",
  };
});

onMounted(() => {
  const { y } = useWindowScroll();
  watch(
    y,
    (newY) => {
      if (newY >= 24) {
        navBarIsTransparent.value = false;
      } else if (newY === 0) {
        navBarIsTransparent.value = true;
      }
    },
    { immediate: true },
  );

  Object.keys(dropdowns).forEach((key) => {
    const ele = dropdowns[key];
    const dropdown = new Dropdown(ele.menu, ele.trigger, {
      placement: "bottom",
      triggerType: "click",
    });

    ele.dropdown = dropdown;
  });

  if (
    collapse.targetEl.value instanceof HTMLElement === false ||
    collapse.toggleEl.value instanceof HTMLElement === false
  ) {
    return;
  }
  collapse.instance = new Collapse(collapse.targetEl.value, collapse.toggleEl.value);
});

function isOutgoing(href?: string) {
  return typeof href === "string" && href.startsWith("http");
}

function addDropdown(type: "trigger" | "menu", idx: string | undefined, el: Element | ComponentPublicInstance | null) {
  if (idx === undefined || el === null || el instanceof HTMLElement === false) {
    return;
  }

  dropdowns[idx] = dropdowns[idx] || {};
  dropdowns[idx][type] = el;
}

function closeDropdown(idx?: string) {
  if (idx === undefined) {
    closeCollapse();
    return;
  }

  dropdowns[idx]?.dropdown.hide();
  closeCollapse();
}

function closeCollapse(): void {
  if (collapse.instance?._visible) {
    collapse.instance.collapse();
  }
}

const showCtaButton = ref(true);
watch(
  () => route.path,
  (newPath) => {
    if (newPath === undefined) {
      return;
    }
    showCtaButton.value = !newPath.startsWith("/fachrichtung");
  },
  {
    immediate: true,
  },
);
</script>

<style scoped>
.translucent {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(16px);
  background: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
}

.navbar {
  transition:
    background 0.4s ease-in-out,
    box-shadow 0.2s linear,
    backdrop-filter 0.3s linear;
}
</style>
